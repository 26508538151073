<template>
    <div class="flex left align-left login">
        <div class=" flex align-left function">
            <div class="box">
                <div class="flex left align-left title">
                    <img src="/logo/logo1.png" alt="">
                    <span style="margin-left: 8px;line-height: 1">{{$t('header.name')}}</span>
                </div>
                <h3>{{$t('login.desc')}}</h3>
                <div class="item-func">
                    <div class="flex left item">
                        <span>{{$t('login.desc1')}}</span>
                        <div>{{$t('service')}}</div>
                    </div>
                    <div class="flex left item">
                        <span>{{$t('login.desc2')}}</span>
                        <div>{{$t('set')}}</div>
                    </div>
                    <div class="flex left item">
                        <span>{{$t('login.desc3')}}</span>
                        <div>{{$t('safe')}}</div>
                    </div>
                    <div class="item">
                        <img src="../../assets/img/banner@2x.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <login-form class="login-pos"/>
        <div class=" flex locale-pos">
            <appLocale/>
        </div>
    </div>
</template>

<script>
    import appLocale from "../../components/appLocale";
    import loginForm from './form'
    export default {
        components: {
            loginForm,
            appLocale,
        }
    }
</script>

<style lang="scss" scoped>
    .login {
        width: 100%;
        min-width: 1080px;
        height: 100%;
        overflow: hidden;
        position: relative;
        background: url("../../assets/img/back@2x.png") no-repeat center;
        background-size: cover;

        h3{
            font-weight: 600;
            color: rgba(0,0,0,0.85);
            margin-bottom: 30px;
        }
    }
    .function{


        .title{
            font-size: 20px;
            font-weight: 400;
            color: #073B70;
            margin-bottom: 130px;
            img{
                height: 20px;
            }
        }
        .box{

        }
    }
    .item-func{
        font-size: 16px;
        font-weight: 400;
        .item{
            margin-bottom: 30px;
            >span{
                color: #7E818A;
            }
            >div{
                color: #073B70;
                margin-left: 10px;
            }
            img{
                width: 540px;
            }
        }


    }

    .locale-pos{
        color: #F5F7FA;
        &::v-deep(a){
            color: #F5F7FA;
        }
    }

    @media (max-width: 1059px){
        .locale-pos{
            position: absolute;
            top: 8vh;
            right: 10vw;
        }
        .function{
            margin-top: 8vh;
            margin-left: 4vw;
        }
        .login-bg{
            width: 0;
            overflow: hidden;
        }
        .login-pos{
            position: absolute;
            top: calc(50% - 211px - 36px);
            right: calc(50% - 230px);
            z-index: 9;
        }
    }
    @media (min-width: 1060px){
      .locale-pos{
        position: absolute;
        top: 8vh;
        right: 3vw;
      }

      .function{
        margin-top: 8vh;
        margin-left: 12vw;
      }

      .login-pos{
        position: absolute;
        top: 18vh;
        right: calc(65% - 430px - 200px);
        z-index: 9;
      }
    }
    @media (min-width: 1320px){
        .locale-pos{
            position: absolute;
            top: 8vh;
            right: 12vw;
        }

        .function{
            margin-top: 8vh;
            margin-left: 12vw;
        }

        .login-pos{
            position: absolute;
            top: 18vh;
            right: calc(50% - 430px - 200px);
            z-index: 9;
        }
    }

</style>