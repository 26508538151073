<template>
    <a-modal
            :visible="show"
            :title="title"
            width="800px"
            @cancel="handleCancel"
            :closable="showClose"
            :maskClosable="showClose"
            :keyboard="showClose">
        <template #footer>
            <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="onSubmit">{{$t('ok')}}</a-button>
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules" :colon="false" :label-col="{span:4}"
                :wrapper-col="{span:20}">
            <a-form-item :label-col="{span:0}" :wrapper-col="{span:24}">
                <a-alert v-if="formState.err" :message="$t('pwdPlz3')" type="error" show-icon   />
                <a-alert v-else :message="$t('pwdPlz5')" type="info" show-icon   />
            </a-form-item>
            <a-form-item :label="$t('oldPwd')" name="oldPwd">
                <a-input-password  v-model:value="formState.oldPwd"  />
            </a-form-item>
            <a-form-item :label="$t('newPwd')" name="newPwd">
                <a-input-password  v-model:value="formState.newPwd" :placeholder="$t('pwdPlz')" />
            </a-form-item>
            <a-form-item :label="$t('rePwd')" name="rePwd">
                <a-input-password  v-model:value="formState.rePwd" :placeholder="$t('pwdPlz')" />
            </a-form-item>

        </a-form>

    </a-modal>
</template>

<script>
    import {ref, reactive, toRaw, computed} from 'vue'
    import i18n from "../../../locale/main";
    import {message} from 'ant-design-vue'
    import {watch} from 'vue'
    import {updateSelfPwd} from '../../../service/user'
    import {useStore} from "vuex";
    export default {
        emits: ['close'],
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            title:{
                type: String,
                default: i18n.global.t('editPwd'),
            },
            info: {
                type: Object,
                default:null,
            },
            showClose:{
                type: Boolean,
                default: true,
            }
        },
        setup(props,ctx) {
            let t = i18n.global.t
            let store = useStore()
            const info = computed(()=>store.state.user.userInfo)
            const loading = ref(false);
            const formRef = ref();
            const formState = reactive({
                oldPwd: '',
                newPwd: '',
                rePwd: '',
                err: false,
            });
            let pwdReg =new RegExp('^[0-9A-Za-z_!@#$%^&*]{6,32}$') ;
            watch(()=>props.show,()=>{
                if(props.show){
                    formState.oldPwd=''
                    formState.newPwd=''
                    formState.rePwd=''
                    formState.err=false
                }
            })
            const onSubmit = async () => {
                try {
                    await formRef.value.validate()
                    if(formState.rePwd!==formState.newPwd){
                        formState.err=true
                        return
                    }
                    loading.value = true;

                    await updateSelfPwd(toRaw(formState))

                    await store.dispatch('user/setInfo',info.value)

                    loading.value = false;
                    ctx.emit('close', true)
                    message.success(t('updateOk'))
                    formRef.value.resetFields()
                }catch (e) {
                    console.log(e)
                    loading.value = false;
                }
            };

            let validatePwd1 = async (rule, value) => {
                if(!value){
                    return Promise.reject(t('pwdPlz1'))
                }
                if(!pwdReg.test(value)){
                    return Promise.reject(t('pwdPlz'))
                }

                return Promise.resolve();
            };
            let validatePwd2 = async (rule, value) => {
                if(!value){
                    return Promise.reject(t('pwdPlz2'))
                }
                if(!pwdReg.test(value)){
                    return Promise.reject(t('pwdPlz'))
                }
                if(value===formState.newPwd){
                    formState.err=false
                }

                return Promise.resolve();
            };

            const rules = {
                newPwd:[{validator: validatePwd1, trigger: 'blur',},],
                rePwd: [{validator: validatePwd2, trigger: 'blur',},],
            }
            const handleCancel = () => {
                formRef.value.resetFields()
                ctx.emit('close', false)
            };
            return {
                formState,
                onSubmit,
                rules,
                formRef,
                loading,
                handleCancel,

            };
        }
    }
</script>

<style scoped>

</style>