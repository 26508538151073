<template>
    <div class="login-form">
        <h3 class="form-title">{{$t('login.welcome')}}</h3>
        <a-form ref="formRef" :model="formState" :rules="rules" :label-col="{span:0}" :wrapper-col="{span:24}">
            <a-form-item label="" name="acct">
                <a-input :placeholder="$t('login.plzAcct')" v-model:value="formState.acct"/>
            </a-form-item>
            <a-form-item label="" name="pwd">
                <a-input-password :placeholder="$t('login.plzPwd')" v-model:value="formState.pwd"/>
            </a-form-item>
            <a-form-item label="" name="code">
                <div class="flex between" style="width: 100%">
                    <a-input :placeholder="$t('login.plzCode')" allowClear v-model:value="formState.code"
                             @keypress.enter="onSubmit" style="width: 60%"/>
                    <img style="width: 36%;height: 32px;cursor: pointer;" @click="changeImgCode" :src="imgCode.img"
                         alt="">
                </div>
            </a-form-item>
            <a-form-item label="" class="flex between">
                <a-button type="primary" :loading="loading" @click="onSubmit" block>{{$t('login.loginBtn')}}</a-button>
            </a-form-item>
            <a-form-item label="" style="margin-bottom: 0;">
                <a class="flex forget" href="javascript:;"
                   @click="$router.push({name:'forget'})">{{$t('login.forget')}}</a>
            </a-form-item>
        </a-form>
        <update-pwd :info="info" :title="$t('users')+':'+info.loginName+' '+$t('login.firstLogin')" :show="action.pwdShow" @close="firstLoginOk" :show-close="false"/>
    </div>
</template>

<script>
    import {reactive, toRaw, ref, onMounted, computed} from 'vue'
    import i18n from '../../locale/main'
    import {getImgCode, verifyImgCode} from '../../service/user'
    import {useStore} from 'vuex'
    import {useRouter} from 'vue-router'

    import constName from '../../utils/constName'
    import UpdatePwd from "../user/person/updatePwd";
    import {localStore} from "../../utils/main";
    let route=constName.route
    export default {
        components:{
            UpdatePwd,
        },
        setup() {
            let t = i18n.global.t
            const formRef = ref();
            const loading = ref(false);
            const store = useStore()
            const router = useRouter()
            let {img, UUID} = getImgCode()
            const imgCode = reactive({img, UUID});
            const formState = reactive({
                acct: '',
                pwd: '',
                code: '',
            });

            const action=reactive({
                pwdShow:false,
            })
            const info = computed(()=>store.state.user.userInfo)

            const onSubmit = async () => {
                try {
                    if(loading.value)return;

                    await formRef.value.validate()
                    let data = toRaw(formState)
                    loading.value = true
                    await verifyImgCode({code: data.code, UUID: imgCode.UUID})
                    await store.dispatch('auth/login', data)
                    loading.value = false
                    if(info.value.ifFirstLogin){
                        action.pwdShow=true
                    }else{
                        router.push({name:route.redirect})
                    }

                } catch (e) {
                    loading.value = false
                    changeImgCode()
                    console.log(e)
                }
            };
            const rules = {
                acct: [{required: true, message: t('login.plzAcc1'), trigger: 'blur',},],
                pwd: [{required: true, message: t('login.plzPwd1'), trigger: 'blur',},],
                code: [{required: true, message: t('login.plzCode1'), trigger: 'blur',},],
            }
            const changeImgCode = () => {
                let {img, UUID} = getImgCode()
                imgCode.img = img
                imgCode.UUID = UUID
            }
            onMounted(async ()=>{
                let status=store.getters['auth/check']
                if(await status()){
                    if(info.value.ifFirstLogin){
                        action.pwdShow=true
                    }else{
                        await router.push({name:route.redirect})
                    }
                }
            })
            return {
                info,
                action,
                formState,
                onSubmit,
                rules,
                formRef,
                imgCode,
                changeImgCode,
                loading,
                firstLoginOk(state){
                    action.pwdShow=false
                    if(state){
                        router.push({name:route.redirect})
                    }else{
                        //清除用户信息
                        localStore.clear()
                        store.dispatch('appTabs/clear')
                        store.dispatch('device/clear')
                        store.dispatch('user/clear')
                        store.dispatch('log/clear')
                    }
                }
            };
        },
    }
</script>

<style lang="scss" scoped>
    .login-form {
        padding: 40px;
        width: 460px;
        background: #FFFFFF;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        .form-title {
            margin-bottom: 40px;

            &:after {
                content: '';
                display: block;
                width: 42px;
                height: 4px;
                background: #1890FC;
                margin-top: 5px;
            }
        }

        .forget {
            font-size: 16px;
            font-weight: 400;
            color: #1890FC;

            &:before, &:after {
                content: '';
                display: block;
                width: 32px;
                height: 1px;
                background: #1890FC;
            }
        }
    }
</style>